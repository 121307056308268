 // Add the following code if you want the name of the file appear on select
 $(".custom-file-input").on("change", function () {
     var fileName = $(this).val().split("\\").pop();
     $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
 });

 //Add filename on file input
 $('input:file').change(
     function (e) {
         $('.customFileName').html('');
         $('.customFileName').html(e.target.files[0].name);
     });


 /*
  *  Contact form submit
  */

 $('#contact-form-submit button[type="submit"]').on('click', function (e) {
    e.preventDefault();

    var name = $("input#name").val();
    var phone = $("input#phone").val();
    var email = $("input#email").val();
    var captcha = $('input#captcha').val();
    var message = $("textarea#message").val();
    var file = $('input#customFile').prop('files')[0];
    var token = $("input[name='_token']").val();
    var ajax = $("input[name='ajax']").val();
    let fd = new FormData();

    var urlPrefix = $('#contact-form-submit').data('url');
    var urlDomain = window.location.origin;

    fd.append('_token', token);
    fd.append('name', name);
    fd.append('phone', phone);
    fd.append('email', email);
    fd.append('captcha', captcha);
    fd.append('message', message);
    fd.append('ajax', ajax);

    if (typeof file !== "undefined") {
        fd.append('attachment', file);
    }

    $.ajax({
        url: urlDomain + "/" + urlPrefix + "/contact/submit-form",
        type: "POST",
        headers: {
            'X-CSRF-TOKEN': token
        },
        data: fd,
        processData: false,
        contentType: false,
        success: function (res) {
            console.log(res.successmessage);
            $('.error-text').html('');
            $('.alert').append(res.successmessage);
            setTimeout(function () {
                $('.alert').addClass('active');
            }, 1000);
         
            setTimeout(function () {
                $('.alert').removeClass('active');
            }, 4500)
            $('#contact-form-submit').trigger("reset")
            
        },
        error: function (err) {
            let { errors } = err.responseJSON;
            $('.error-text').html('');
            $('#name-error').append(errors.name);
            $('#email-error').append(errors.email);
            $('#phone-error').append(errors.phone);
            $('#captcha-error').append(errors.captcha);
            $('#message-error').append(errors.message);

        },
    });
});
