/*
 * Owl carousel
 */
$('.owl-hero').owlCarousel({
  margin: 0,
  items: 1,
  loop: true,
  autoplay: false,
  autoplayTimeout: 4500,
  nav: true,
  dots: true,
  animateOut: 'fadeOut',
  navText: ["<i class='p-chevron-left'></i>", "<i class='p-chevron-right'></i>"],
  // navContainer: '#customNav',
});

$('.owl-popular-products').owlCarousel({
  margin: 20,
  items: 5,
  loop: true,
  autoplay: true,
  autoplayTimeout: 4500,
  nav: true,
  dots: false,
  animateOut: 'fadeOut',
  navText: ["<i class='p-chevron-left'></i>", "<i class='p-chevron-right'></i>"],
  navContainer: '#customNavPopularProducts',
  responsive: {
    0: { 
      items: 2
    },
    700: {
      items: 4,
      margin: 0,
    },
    1200: {
      items: 5,
    }
  }
});

$('.owl-popular-categories').owlCarousel({
  margin: 20,
  items: 5,
  loop: true,
  autoplay: false,
  autoplayTimeout: 3000,
  nav: true, 
  dots: true, 
  animateOut: 'fadeOut',
  navText: ["<i class='p-chevron-left'></i>", "<i class='p-chevron-right'></i>"],
  // navContainer: '#customNavPopularCategories',
  responsive: {
    0: {
      items: 1
    },
    700: {
      items: 3,
    },
    1200: {
      items: 5,
    },
    1600: {
      items: 5,
    },
  }
});

$('.owl-testimonials').owlCarousel({
  margin: 20,
  items: 3,
  loop: true,
  autoplay: true, 
  autoplayTimeout: 4500,
  nav: false,
  center: true, 
  dots: false,
  // animateOut: 'fadeOut',
  navText: ["<i class='p-chevron-left'></i>", "<i class='p-chevron-right'></i>"],
  responsive: {
    0: {
      items: 1
    },
    700: {
      items: 2,
    },
    1200: {
      items: 3,
    }
  }
});